import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Podcast = ({ data }) => (
  <Layout>
    <SEO title="Podcast" />
    <div className="podcast">
      <div
        className="podcast-grid"
        data-sal="slide-bottom"
        data-sal-delay="600"
        data-sal-easing="ease"
      >
        {data.allWordpressPost.edges.map(({ node }) => (
          <Link
            key={node.id}
            className="podcast-grid__link"
            to={`podcast/${node.slug}`}
          >
            <div className="podcast-grid__post">
              <div className="podcast-grid__postAbout">
                <h3>{node.title}</h3>
                <div>
                  <p>
                    <span
                      dangerouslySetInnerHTML={{ __html: node.acf.duracion }}
                    />{" "}
                    ·{" E"}
                    <span
                      dangerouslySetInnerHTML={{ __html: node.acf.episodio }}
                    />
                  </p>
                  <p>{node.acf.invitado}</p>
                </div>
              </div>
              <img
                className="podcast-grid__postCover"
                alt="Podcast Cover"
                src={
                  node.featured_media.localFile.childImageSharp.resolutions.src
                }
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  </Layout>
)

export default Podcast

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: { fields: [date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          title
          slug
          content
          acf {
            duracion
            episodio
            invitado
          }
          featured_media {
            localFile {
              childImageSharp {
                resolutions {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
